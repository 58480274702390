import React from "react"

import LayoutMain from "../components/layout-main/layout-main"
import SeoHeaders from "../components/seo-headers/seo-headers"
import HeaderMain from "../components/header-main/header-main"

const NotFoundPage = () => (
  <LayoutMain className="page page-404">
    <SeoHeaders title="404: Not found" />
    <HeaderMain />
    <section>
      <div className="content-section">
        <div className="page-content">
          <h1 className="main-heading">NOT FOUND</h1>
          <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
        </div>
      </div>
    </section>
  </LayoutMain>
)

export default NotFoundPage
